import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles.css';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as XLSX from 'xlsx'; 
import {
  fetchCustomersThunk,
  addCustomerThunk,
  updateCustomerThunk,
  removeCustomerThunk,
} from './customerSlice';
import ExcelDataComponent from './ExcelData';
import { v4 as uuidv4 } from 'uuid';

const CustomerManagement = () => {
  const dispatch = useDispatch();
  const { customers} = useSelector((state) => state.customers);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [newCustomer, setNewCustomer] = useState({
    id:'',
    name: '',
    email: '',
    phone: '',
    status: 'Active',
    work: '',    
    location : '',     
    followUpDate: '',    
    enquiryDate: '',      
  });

  useEffect(() => {
    dispatch(fetchCustomersThunk());
  }, [dispatch]);

  const columnDefs = [
    {
      headerName: 'S.No',
      valueGetter: (params) => params.node.rowIndex + 1,
      sortable: false,
      filter: false,
      width: 70,
    },
    { field: 'name', headerName: 'Name' },
    { field: 'email', headerName: 'Email' },
    { field: 'phone', headerName: 'Phone' },
    { field: 'work', headerName: 'Work' },
    { field: 'location', headerName: 'Location' },
    { field: 'enquiryDate', headerName: 'Enquiry Date' },
    { field: 'followUpDate', headerName: 'Follow-up Date' },
    { field: 'status', headerName: 'Status' },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: (params) => (
        <div style={{ display: 'flex', gap: '4px', justifyContent: 'space-between', width: '150px' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => confirmRemoveCustomer(params.data.id)}
            disabled={params.data.status === 'Active'}
            size="small"
            style={{ padding: '2px 4px', fontSize: '0.7rem' }}
          >
            <DeleteIcon fontSize="small" />
          </Button>
          {params.data.status === 'Inactive' ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setActive(params.data.id)}
              size="small"
              style={{ padding: '2px 4px', fontSize: '0.7rem' }}
            >
              Activate
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setInactive(params.data.id)}
              size="small"
              style={{ padding: '2px 4px', fontSize: '0.7rem' }}
            >
              Inactive
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditCustomer(params.data)}
            size="small"
            style={{ padding: '2px 4px', fontSize: '0.7rem', margin: "auto" }}
          >
            <EditIcon fontSize="small" />
          </Button>
        </div>
      ),
    },
  ];

  const confirmRemoveCustomer = (id) => {
    const confirmed = window.confirm('Are you sure you want to remove this customer?');
    if (confirmed) {
      dispatch(removeCustomerThunk(id));
    }
  };

  const setInactive = (id) => {
    dispatch(updateCustomerThunk({ id, status: 'Inactive' }));
  };

  const setActive = (id) => {
    dispatch(updateCustomerThunk({ id, status: 'Active' }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewCustomer({
      id : '',
      name: '',
      email: '',
      phone: '',
      status: 'Active',
      work: '',
      location : '',
      followUpDate: '',
      enquiryDate: '',
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewCustomer({ ...newCustomer, [name]: value });
  };

  const handleAddCustomer = () => {
    // Create a new customer object with a randomly generated ID if it's a new customer
    const customerToSend = newCustomer.id ? newCustomer : { ...newCustomer, id: uuidv4() };
  
    if (newCustomer.id) {
      dispatch(updateCustomerThunk(newCustomer));
   
    } else {
      dispatch(addCustomerThunk(customerToSend)); // Send customer with id
    
    }
  
    handleClose();
  };

  const handleEditCustomer = (customer) => {
    setNewCustomer(customer); // Set the selected customer's data to newCustomer state
    setOpen(true); // Open the modal for editing
  };
    const onGridReady = (params) => {
      setGridApi(params.api);
    };

    const ExcelData = {
      exportToCSV: (gridApi) => {
        if (!gridApi) {
          console.error('Grid API is not defined');
          return;
        }
    
        // Get all row data from the grid, excluding the id field
        const rowData = [];
        gridApi.forEachNode((node) => {
          const { id, ...customerData } = node.data; // Exclude the `id` field
          rowData.push(customerData);
        });
    
        // Check if rowData is populated
        if (rowData.length === 0) {
          console.error('No data found to export');
          return;
        }
    
      
        // Convert row data to CSV format
        const csvContent = XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(rowData));
    
        // Create a Blob for the CSV file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        
        // Create a link element to download the CSV file
        const link = document.createElement('a');
        const filename = 'Customers_Data.csv';
        
        // Create a URL for the Blob and set it as the link's href
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        
        // Append the link to the body
        document.body.appendChild(link);
        
        // Programmatically click the link to trigger the download
        link.click();
        
        // Clean up by removing the link from the document
        document.body.removeChild(link);
        
        console.log(`CSV file has been created and downloaded as ${filename}`);
      },
    };
    
    
  return (
    <>
      <div>
        <h2 className="text-2xl font-bold text-gray-500 mb-4">All Customers</h2>
        <Button variant="contained" color="primary" style={{ marginBottom: '5px' }} onClick={handleClickOpen}>
          Add Customer
        </Button>
      </div>

      <div className="ag-theme-alpine" style={{ width: '100%', height: 'auto' }}>
      <AgGridReact
        onGridReady={onGridReady}
        rowData={customers}
        columnDefs={columnDefs}
        animateRows={true}
        getRowClass={(params) => (params.node.rowIndex % 2 === 0 ? 'even-row' : 'odd-row')}
        domLayout="autoHeight"
      />
      </div>

      {/* Modal for adding or editing a customer */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{newCustomer.id ? 'Edit Customer' : 'Add New Customer'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the details of the customer.</DialogContentText>
          <TextField
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newCustomer.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={newCustomer.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="phone"
            label="Phone"
            type="tel"
            fullWidth
            variant="outlined"
            value={newCustomer.phone}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="work"
            label="Work"
            type="text"
            fullWidth
            variant="outlined"
            value={newCustomer.work}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="location"
            label="Location"
            type="text"
            fullWidth
            variant="outlined"
            value={newCustomer.location}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="followUpDate"
            label="Follow-up Date"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={newCustomer.followUpDate}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="enquiryDate"
            label="Enquiry Date"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={newCustomer.enquiryDate}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddCustomer} color="primary">
            {newCustomer.id ? 'Update' : 'Add'} 
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ marginTop: '20px', textAlign: 'right' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => ExcelData.exportToCSV(gridApi)}
        >
          Export Data
        </Button>
      </div>



      <ExcelDataComponent/>
    </>
  );
};

export default CustomerManagement; 
