import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEmployeeData, fetchAttendanceData } from './payslipApi';

// Thunks for fetching employees and attendance data
export const fetchPayslipData = createAsyncThunk(
  'payslip/fetchPayslipData',
  async (_, { rejectWithValue }) => {
    try {
      const employees = await fetchEmployeeData();
      const attendance = await fetchAttendanceData();
      return { employees, attendance };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const payslipSlice = createSlice({
  name: 'payslip',
  initialState: {
    employees: [],
    attendance: [],
    payslips: [],
    status: 'idle', // idle, loading, succeeded, failed
    error: null,
  },
  reducers: {
    generatePayslips: (state) => {
      state.payslips = state.employees.map((employee) => {
        const employeeAttendance = state.attendance.filter(
          (att) => att.id === employee.id
        );
        return calculatePayslip(employee, employeeAttendance);
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayslipData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPayslipData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.employees = action.payload.employees;
        state.attendance = action.payload.attendance;
      })
      .addCase(fetchPayslipData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { generatePayslips } = payslipSlice.actions;

export default payslipSlice.reducer;

// Helper function to calculate payslip
const calculatePayslip = (employee, attendanceRecords) => {
  const totalDays = 30;
  const presentDays = attendanceRecords.filter(
    (record) => record.status === 'Present' || record.status === 'WFH'
  ).length;

  const salaryPerDay = parseFloat(employee.salary) / totalDays;
  const totalSalary = salaryPerDay * presentDays;

  const deductionAmount = salaryPerDay * (totalDays - presentDays);
  const netSalary = totalSalary - deductionAmount;

  return {
    name: employee.name,
    salary: employee.salary,
    daysWorked: presentDays,
    totalSalary: totalSalary.toFixed(2),
    netSalary: netSalary.toFixed(2),
  };
};
