import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem'; 

const EditModal = ({ open, onClose, record, onSave }) => {
    
    const [localRecord, setLocalRecord] = useState(record);
 
    useEffect(() => {
        setLocalRecord(record);
       
    }, [record]);

    const handleSave = () => {
        onSave(localRecord);
        onClose();
    };

    const handleChange = (field, value) => {
        setLocalRecord({ ...localRecord, [field]: value });
    };
    
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Attendance Record</DialogTitle>
            <DialogContent>
                
                <TextField
                    margin="dense"
                    label="Date"
                    type="date"
                    fullWidth
                    value={localRecord?.date || ''} 
                    onChange={(e) => handleChange('date', e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="dense"
                    label="In Time"
                    type="text"
                    fullWidth
                    value={localRecord?.inTime || ''}
                    onChange={(e) => handleChange('inTime', e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Out Time"
                    type="text"
                    fullWidth
                    value={localRecord?.outTime || ''}
                    onChange={(e) => handleChange('outTime', e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Status"
                    select
                    fullWidth
                    value={localRecord?.status || ''}
                    onChange={(e) => handleChange('status', e.target.value)}
                >
                    <MenuItem value="Present">Present</MenuItem>
                    <MenuItem value="Absent">Absent</MenuItem>
                    <MenuItem value="WFH">Work From Home</MenuItem>
                    <MenuItem value="Paid Leave">Paid Leave</MenuItem>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditModal;
