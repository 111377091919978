// src/api/api.js

const BASE_URL = 'https://snmr-erp-backend.onrender.com/customers'; // Change this to your actual base URL if needed

// Fetch all customers
export const fetchCustomers = async () => {
    const response = await fetch(`${BASE_URL}/getAllCustomers`);
    if (!response.ok) {
        throw new Error('Failed to fetch customers');
    }
    return await response.json();
};

// Add a new customer
export const addCustomer = async (customerData) => {
    const response = await fetch(`${BASE_URL}/addCustomer`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(customerData),
    });
    if (!response.ok) {
        throw new Error('Failed to add customer');
    }
    return await response.json();
};

// Update an existing customer
export const updateCustomer = async (updatedCustomer) => {
    const { id } = updatedCustomer;

    const response = await fetch(`${BASE_URL}/updateCustomer/${id}`, {
        method: 'PATCH', 
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedCustomer),
    });
    if (!response.ok) {
        throw new Error('Failed to update customer');
    }
    return await response.json();
};

// Remove a customer
export const removeCustomer = async (id) => {
    const response = await fetch(`${BASE_URL}/deleteCustomer/${id}`, {
        method: 'DELETE',
    });
    if (!response.ok) {
        throw new Error('Failed to remove customer');
    }
};
