import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { useDispatch } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { addCustomerThunk } from './customerSlice';
import { v4 as uuidv4 } from 'uuid';

const ExcelUploader = () => {
  const [excelData, setExcelData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [open, setOpen] = useState(false);
  const [newCustomer, setNewCustomer] = useState({});
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the data is in the first sheet
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        setExcelData(jsonData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleRowDoubleClick = (params) => {
    const rowData = params.data;
    const customerData = {
      id: rowData.column0,
      name: rowData.column1,
      email: rowData.column2,
      phone: rowData.column3,
      work: rowData.column4,
      location: rowData.column5,
      followUpDate: rowData.column6,
      enquiryDate: rowData.column7,
    };
    setNewCustomer(customerData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddCustomer = () => {
    const customerToSend = {
      ...newCustomer,
      id: newCustomer.id || uuidv4(), // Assign a new UUID if ID is not already present
      status: 'Active', // Default status if not set
    };
    
    dispatch(addCustomerThunk(customerToSend))
      .then(() => {
          handleClose(); // Close the dialog after successfully adding customer
        setNewCustomer({}); // Reset newCustomer for the next entry
      })
      .catch((error) => {
        console.error("Failed to add customer:", error);
      });
  };

  // Prepare column definitions for AG Grid
  const columnDefs = excelData.length > 0
    ? excelData[0].map((header, index) => ({
        headerName: header,
        field: `column${index}`,
        sortable: true,
        filter: true,
      }))
    : [];

  // Prepare row data for AG Grid
  const rowData = excelData.length > 0
    ? excelData.slice(1).map((row) => {
        const rowDataObject = {};
        row.forEach((cell, cellIndex) => {
          rowDataObject[`column${cellIndex}`] = cell;
        });
        return rowDataObject;
      })
    : [];

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 4, p: 3, boxShadow: 3, borderRadius: 2, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h4" gutterBottom align="center" className='text-gray-500 '>
        Excel Data Uploader
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Button
          variant="contained"
          component="label"
          sx={{ backgroundColor: '#1976d2', color: '#fff', textTransform: 'none', px: 4 }}
        >
          Upload Excel File
          <input
            type="file"
            accept=".xlsx, .xls"
            hidden
            onChange={handleFileChange}
          />
        </Button>
      </Box>
      {fileName && (
        <Typography variant="subtitle1" align="center" sx={{ mb: 2, color: '#555' }}>
          File uploaded: {fileName}
        </Typography>
      )}
      <Typography variant="h6" sx={{ mb: 2, color: '#333' }}>Parsed Data:</Typography>

      {excelData.length > 0 ? (
        <Box className="ag-theme-alpine" sx={{ height: 400, width: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            pagination={true}
            onRowDoubleClicked={handleRowDoubleClick} // Open modal on double-click
          />
        </Box>
      ) : (
        <Typography variant="body1" align="center" sx={{ color: '#777' }}>
          No data uploaded yet.
        </Typography>
      )}

      {/* Dialog for editing customer */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Customer</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the details of the customer.</DialogContentText>
          {['name', 'email', 'phone', 'work', 'location'].map((field) => (
            <TextField
              key={field}
              margin="dense"
              name={field}
              label={field.charAt(0).toUpperCase() + field.slice(1)}
              type="text"
              fullWidth
              variant="outlined"
              value={newCustomer[field] || ''}
              onChange={handleChange}
            />
          ))}
          {['followUpDate', 'enquiryDate'].map((field) => (
            <TextField
              key={field}
              margin="dense"
              name={field}
              label={field.replace(/([A-Z])/g, ' $1')}
              type="date"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={newCustomer[field] || ''}
              onChange={handleChange}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleAddCustomer} color="primary">
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExcelUploader;
