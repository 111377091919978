import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWorkRecord, updateWorkRecord, fetchWorkRecords } from './workSlice';

const TasksTable = () => {
    const dispatch = useDispatch();
    const workRecords = useSelector((state) => state.work.workRecords);
  
    const loading = useSelector((state) => state.work.loading);
    const error = useSelector((state) => state.work.error);

    // State for the selected date and filter criteria
    const [selectedDate, setSelectedDate] = useState('');
    const [filterCriteria, setFilterCriteria] = useState('All');

    useEffect(() => {
        dispatch(fetchWorkRecords());
    }, [dispatch]);

    const handleComplete = (_id) => {
        dispatch(updateWorkRecord({ _id, updatedData: { status: 'Completed' } }));
    };

    const handleDelete = (_id) => {
        dispatch(deleteWorkRecord(_id));
    };

    // Filter records based on selected date and filter criteria
    const filteredTasks = workRecords.filter(record => {
        const dateMatch = selectedDate ? record.date === selectedDate : true;
        
        const criteriaMatch = filterCriteria === 'All' || record.status === filterCriteria;
        return dateMatch && criteriaMatch;
    });

   

    // No need to filter for active tasks separately here; instead, include them directly in the filteredTasks

    const exportData = () => {
        const csvRows = [];
        const headers = ['Employee ID', 'Employee Name', 'Task Description', 'Date', 'Status'];
        csvRows.push(headers.join(','));

        filteredTasks.forEach(record => {
            const row = [
                record.employeeId,
                record.name,
                record.taskDescription,
                record.date,
                record.status,
            ];
            csvRows.push(row.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'tasks.csv';
        a.click();
        URL.revokeObjectURL(url);
    };


    return (
        <div className="p-4">
            <div className="flex justify-between mb-4">
                <div className="flex items-center">
                    <label htmlFor="date-filter" className="block text-sm font-medium text-gray-700">Filter by Date:</label>
                    <input
                        type="date"
                        id="date-filter"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        className="ml-2 block px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div className="flex items-center">
                    <label htmlFor="status-filter" className="block text-sm font-medium text-gray-700">Filter by Status:</label>
                    <select
                        id="status-filter"
                        value={filterCriteria}
                        onChange={(e) => setFilterCriteria(e.target.value)}
                        className="ml-2 block px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="All">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Completed">Completed</option>
                    </select>
                </div>
            </div>
      
            {loading && <p>Loading...</p>}
            {error && <p className="text-red-600">{error}</p>}
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee ID</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Task Description</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {filteredTasks.map((record) => (
                      
                        <tr key={record._id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{record.employeeId}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{record.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{record.taskDescription}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{record.date}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{record.status}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                <button
                                    onClick={() => handleComplete(record._id)}
                                    className="text-blue-600 hover:text-blue-900"
                                >
                                    Complete
                                </button>
                                <button
                                    onClick={() => handleDelete(record._id)}
                                    className="ml-4 text-red-600 hover:text-red-900"
                                >
                                    Remove
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button
                onClick={exportData}
                className="mb-4 inline-flex items-center px-4 py-2 bg-green-500 text-white font-semibold rounded-md shadow-sm hover:bg-green-600"
            >
                Export Data
            </button>
        </div>
    );
};

export default TasksTable;
