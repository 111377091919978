import React from 'react';
import { motion } from 'framer-motion';
import CustomerManage from '../features/customerManagement/CustomerManage';

const Customer = () => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <CustomerManage />
      </motion.div>
    </div>
  );
};

export default Customer;
