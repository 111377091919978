import React from 'react';
import { motion } from 'framer-motion';
import EmployeeAttendance from '../features/attendance/EmployeeAttendance';

const Attendance = () => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <EmployeeAttendance />
      </motion.div>
    </div>
  );
};

export default Attendance;
