import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: !!localStorage.getItem('token'), // Check if token exists in localStorage
    user: JSON.parse(localStorage.getItem('user')), // Parse the stored user info
    token: localStorage.getItem('token'), // Load token from localStorage
    error: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.error = null;

      // Save the user data and token in localStorage for persistence
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', action.payload.token);
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.error = action.payload;

      // Clear localStorage on login failure
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;

      // Clear localStorage on logout
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
  },
});

export const { loginSuccess, loginFailure, logout } = authSlice.actions;

// Updated login function to authenticate against the backend
export const login = (credentials) => async (dispatch) => {
  try {
    // Send login request to the backend
    const response = await fetch('https://snmr-erp-backend.onrender.com/users/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials), // Pass email and password in the request body
    });

    if (!response.ok) {
      throw new Error('Login failed. Please check your credentials.');
    }

    const data = await response.json();
    const user = { email: credentials.email }; // You can extend this to include more user data if needed

    dispatch(loginSuccess({ user, token: data.token })); // Use the token from the response
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

// Function to restore auth state from localStorage
export const restoreAuth = () => (dispatch) => {
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');

  if (token && user) {
    dispatch(loginSuccess({ user: JSON.parse(user), token }));
  }
};

export default authSlice.reducer;
