
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';

import Login from './pages/Login';
import Home from './pages/Home';
import ProtectedRoute from './features/auth/ProtectedRoute'; 
import { restoreAuth } from './features/auth/authSlice.js';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Restore auth state from localStorage when the app loads
    dispatch(restoreAuth());
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route 
          path="/" 
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;
