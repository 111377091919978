import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import EmployeeForm from '../features/employee/EmployeeForm';
import EmployeeList from '../features/employee/EmployeeList';
import Chart from '../features/employee/Chart';
import { useDispatch } from 'react-redux';
import { fetchEmployeesThunk } from '../features/employee/employeeSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  // Fetch employees when the component mounts or when refresh is triggered
  useEffect(() => {
    dispatch(fetchEmployeesThunk());
  }, [dispatch, refresh]);

  // Function to handle form submission and refresh
  const handleFormSubmit = () => {
    setRefresh((prev) => !prev); // Toggle the refresh state
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <Chart />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.3, ease: 'easeOut' }}
      >
        <EmployeeList />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.6, ease: 'easeOut' }}
      >
        <EmployeeForm onSubmit={handleFormSubmit} /> {/* Pass the handler to the form */}
      </motion.div>
    </div>
  );
};

export default Dashboard;
