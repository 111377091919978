import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../features/auth/authSlice'; // Adjust path
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import snmrLogo from "./logo.webp"
import {
  Box,
  Drawer,
  CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  CalendarMonth as CalendarMonthIcon,
  Report as ReportIcon,
  AttachMoney as AttachMoneyIcon,
  Logout as LogoutIcon,
 
} from '@mui/icons-material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Dashboard from './Dashboard'; 
import Attendance from './Attendance'; 
import Payslip from './Payslip'; 
import Report from './Report'; 
import Customer from './Customer';

const drawerWidth = 240;

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Home() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [activePage, setActivePage] = React.useState('dashboard');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavClick = (page) => {
    setActivePage(page);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const renderContent = () => {
    switch (activePage) {
      case 'dashboard':
        return <Dashboard />;
      case 'attendance':
        return <Attendance />;
      case 'report':
        return <Report />;
      case 'payslip':
        return <Payslip />;
      case 'customer':
        return <Customer />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
      
        <Toolbar sx={{
                backgroundColor: '#442469', 
                color: '#ffffff', 
          }}>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography 
            variant="h6" 
            noWrap 
            component="div"
            sx={{
                fontFamily: 'Arial, sans-serif', 
                fontWeight: 'bold', 
                fontSize: '1.5rem',
                textAlign: 'center', 
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
        >
            SNMR EMPLOYEE MANAGEMENT SOFTWARE
        </Typography>

        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#1b3a6b', 
            color: '#ffffff', // Text color
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
         <img src={snmrLogo} alt='logo' width="70%" height="80%"  className='pt-2 pb-2'/>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{ color: '#ffffff' }} /> : <ChevronRightIcon sx={{ color: '#ffffff' }} />}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ backgroundColor: '#ffffff' }} />
        <List>
          {[
            { text: 'Dashboard', icon: <DashboardIcon /> },
            { text: 'Attendance', icon: <CalendarMonthIcon /> },
            { text: 'Report', icon: <ReportIcon /> },
            { text: 'Payslip', icon: <AttachMoneyIcon /> },
            { text: 'Customer', icon: <SupportAgentIcon  /> }
          ].map((item) => (
            <ListItem key={item.text} disablePadding>
             <ListItemButton
            onClick={() => handleNavClick(item.text.toLowerCase())}
            sx={{
              background: activePage === item.text.toLowerCase()
                ? 'linear-gradient(135deg, #82a9b5 0%, #4a6572 100%)' // Gradient color for the active menu item
                : 'transparent',
              borderRadius: '8px', // Adds a slight roundness to the corners
              transform: activePage === item.text.toLowerCase() ? 'scale(1.05)' : 'none', // Slightly enlarges the active item
              transition: 'all 0.3s ease-in-out', // Smooth transition for the effects
              '&:hover': {
                backgroundColor: '#334155', // Hover color
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Adds a shadow effect on hover
              },
              color: '#ffffff',
              padding: '10px 16px', // Adds some padding for a better look
            }}
          >
                <ListItemIcon sx={{ color: '#ffffff' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List sx={{ marginTop: 'auto' }}>
          <ListItem key="logout" disablePadding>
            <ListItemButton
              onClick={handleLogout}
              sx={{
                '&:hover': {
                  backgroundColor: '#be123c', 
                },
                color: '#ffffff',
              }}
            >
              <ListItemIcon sx={{ color: '#ffffff' }}><LogoutIcon /></ListItemIcon>
              <ListItemText primary="LogOut" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {renderContent()}
      </Main>
    </Box>
  );
}
