import React from 'react';
import { motion } from 'framer-motion';
import Payslipp from '../features/payslip/Payslip';

const Payslip = () => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <Payslipp />
      </motion.div>
    </div>
  );
};

export default Payslip;
