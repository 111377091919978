import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addEmployeeThunk } from './employeeSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeForm = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [salary, setSalary] = useState('');
  const [joinDate, setJoinDate] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [status] = useState('Active');
  const [photo, setPhoto] = useState('');
  const [error, setError] = useState({});

  const notify = () => toast.success("Employee added successfully!");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset error state
    setError({});

    // Validation state
    let hasError = false;
    const newErrors = {};

    // Phone number validation
    if (phone.length !== 10 || !/^\d{10}$/.test(phone)) {
      newErrors.phone = 'Phone number must be exactly 10 digits and only contain numbers.';
      hasError = true;
    }

    // Employee ID validation
    if (!employeeId) {
      newErrors.employeeId = 'Employee ID is required.';
      hasError = true;
    }

    // Name validation
    if (!name) {
      newErrors.name = 'Name is required.';
      hasError = true;
    }

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailPattern.test(email)) {
      newErrors.email = 'Valid email is required.';
      hasError = true;
    }

    // Role validation
    if (!role) {
      newErrors.role = 'Role is required.';
      hasError = true;
    }

    // Salary validation
    if (!salary || isNaN(salary)) {
      newErrors.salary = 'Salary must be a valid number.';
      hasError = true;
    }

    // Join date validation
    if (!joinDate) {
      newErrors.joinDate = 'Join date is required.';
      hasError = true;
    }

    // Photo validation
    if (!photo) {
      newErrors.photo = 'Photo URL is required.';
      hasError = true;
    }

    // If there are validation errors, set the error state and return
    if (hasError) {
      setError(newErrors);
      return;
    }

    // Create the new employee object
    const newEmployee = { employeeId, name, email, phone, role, salary, joinDate, status, photo };

    // Dispatch the action
    dispatch(addEmployeeThunk(newEmployee))
      .then(response => {
        if (response.error) {
          setError({ api: response.error.message || 'Failed to add employee.' });
        } else {
          // Clear form fields upon successful submission
          notify();  // Call the notify function
          setName('');
          setEmail('');
          setPhone('');
          setRole('');
          setSalary('');
          setJoinDate('');
          setEmployeeId('');
          setPhoto('');
          
          // Call the onSubmit handler to refresh the dashboard
          onSubmit();
        }
      });
  };

  return (
    <div className="p-4">
      <h2 className="text-[1.5rem] font-bold mb-2 text-center text-white shadow-lg bg-slate-400">
        Add Employee
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Employee ID input (Moved to the top) */}
        <div>
          <label htmlFor="employeeId" className="block">Employee ID:</label>
          <input
            type="text"
            id="employeeId"
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
          {error.employeeId && <p className="text-red-500">{error.employeeId}</p>}
        </div>

        {/* Name input */}
        <div>
          <label htmlFor="name" className="block">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
          {error.name && <p className="text-red-500">{error.name}</p>}
        </div>

        {/* Email input */}
        <div>
          <label htmlFor="email" className="block">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
          {error.email && <p className="text-red-500">{error.email}</p>}
        </div>

        {/* Phone input */}
        <div>
          <label htmlFor="phone" className="block">Phone:</label>
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
          {error.phone && <p className="text-red-500">{error.phone}</p>}
        </div>

        {/* Role input */}
        <div>
          <label htmlFor="role" className="block">Role:</label>
          <input
            type="text"
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
          {error.role && <p className="text-red-500">{error.role}</p>}
        </div>

        {/* Salary input */}
        <div>
          <label htmlFor="salary" className="block">Salary:</label>
          <input
            type="number"
            id="salary"
            value={salary}
            onChange={(e) => setSalary(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
          {error.salary && <p className="text-red-500">{error.salary}</p>}
        </div>

        {/* Join Date input */}
        <div>
          <label htmlFor="joinDate" className="block">Join Date:</label>
          <input
            type="date"
            id="joinDate"
            value={joinDate}
            onChange={(e) => setJoinDate(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
          {error.joinDate && <p className="text-red-500">{error.joinDate}</p>}
        </div>

        {/* Photo input */}
        <div>
          <label htmlFor="photo" className="block">Photo URL:</label>
          <input
            type="text"
            id="photo"
            value={photo}
            onChange={(e) => setPhoto(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
          {error.photo && <p className="text-red-500">{error.photo}</p>}
        </div>

        {/* Submit button */}
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Employee
        </button>
      </form>
      <ToastContainer position="bottom-center" /> {/* Position for toast notifications */}
    </div>
  );
};

export default EmployeeForm;
