const BASE_URL = 'https://snmr-erp-backend.onrender.com';

// Fetch employee attendance records
export const fetchAttendance = async () => {
    const response = await fetch(`${BASE_URL}/attendance/getAllAttendance`);
    if (!response.ok) {
        throw new Error('Failed to fetch attendance records');
    }
    const data = await response.json();
    return data;
};

// Mark attendance for an employee
export const markAttendance = async (attendanceRecord) => {
    const response = await fetch(`${BASE_URL}/attendance/markAttendance`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(attendanceRecord),
    });
    if (!response.ok) {
        throw new Error('Failed to mark attendance');
    }
    const data = await response.json();
    return data;
};


export const getAttendanceRecord = async (id, date) => {
    const response = await fetch(`${BASE_URL}/attendance`);
    if (!response.ok) {
        throw new Error('Failed to fetch attendance records');
    }
    const records = await response.json();
    // Filter records by both id and date
    const record = records.find(record => record.date === date);
    return record;
};

export const updateAttendance = async (updatedRecord) => {
    const { _id, } = updatedRecord;
    const response = await fetch(`${BASE_URL}/attendance/updateAttendance/${_id}`, {
        method: 'PATCH', 
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedRecord),
    });

    if (!response.ok) {
        throw new Error('Failed to update attendance');
    }

    return response.json();
};

