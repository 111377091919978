const BASE_URL = 'https://snmr-erp-backend.onrender.com/workrecords';


export const fetchEmployeesApi = async () => {
    const response = await fetch(`${BASE_URL}/getAllWorkRecords`);
    if (!response.ok) {
        throw new Error('Failed to fetch employees');
    }
    const data = await response.json();
    return data;
};

export const fetchWorkByEmployeeAndDate = async (employeeId, date) => {
    const response = await fetch(`${BASE_URL}/workRecords?employeeId=${employeeId}&date=${date}`);
    if (!response.ok) {
        throw new Error('Failed to fetch work records');
    }
    const data = await response.json();
    return data;
};

// Add a new work record
export const addWorkRecordApi = async (workRecord) => {
    const response = await fetch(`${BASE_URL}/addWorkRecord`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(workRecord),
    });
    if (!response.ok) {
        throw new Error('Failed to add work record');
    }
    const data = await response.json();
    return data;
};

// Update an existing work record
export const updateWorkRecordApi = async (id, updatedData) => {

    const response = await fetch(`${BASE_URL}/updateWorkRecord/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
    });
    if (!response.ok) {
        throw new Error('Failed to update work record');
    }
    const data = await response.json();
    return data;
};

// Delete a work record
export const deleteWorkRecordApi = async (id) => {
    const response = await fetch(`${BASE_URL}/deleteWorkRecord/${id}`, {
        method: 'DELETE',
    });
    if (!response.ok) {
        throw new Error('Failed to delete work record');
    }
    return id;
};

// Fetch all work records for a specific date
export const fetchAllWorkByDateApi = async (date) => {
    const response = await fetch(`${BASE_URL}/workRecords?date=${date}`);
    if (!response.ok) {
        throw new Error('Failed to fetch all work records by date');
    }
    const data = await response.json();
    return data;
};



export const fetchAllWorkRecords = async () => {
    try {
        const url = `${BASE_URL}/getAllWorkRecords`; // Assuming this endpoint returns all work records

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch work records');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};

