import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addWorkRecord } from './workSlice';
import TaskTable from './TaskTable';

import { fetchEmployees } from '../employee/employeeApi';


const Work = () => {
    const [employeeId, setEmployeeId] = useState('');
    const [name, setName] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [date, setDate] = useState('');
    const [employees, setEmployees] = useState([]);
    const [ status ] = useState("Pending")
    const dispatch = useDispatch();

    useEffect(() => {
        const loadEmployees = async () => {
            try {
                const employeesData = await fetchEmployees();
                const filterData = employeesData.filter((val)=>val.status === "Active")
                setEmployees(filterData);
            } catch (err) {
                console.error('Failed to fetch employees:', err);
            }
        };

        loadEmployees();
    }, []);

    const handleEmployeeChange = (e) => {
        const selectedEmployeeId = e.target.value;
        const selectedEmployee = employees.find(employee => employee.employeeId === selectedEmployeeId);
        
        setEmployeeId(selectedEmployeeId);
        setName(selectedEmployee ? selectedEmployee.name : '');
    };
 
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addWorkRecord({ employeeId, name,status, taskDescription, date }));
        setEmployeeId('');
        setTaskDescription('');
        setDate('');
    };
   
    return (
        <>
            <form onSubmit={handleSubmit} className="space-y-4 p-4">
                <div>
                    <label htmlFor="employee" className="block text-sm font-medium text-gray-700">Select Employee</label>
                    <select
    id="employee"
    value={employeeId}
    onChange={handleEmployeeChange}
    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
    required
>
    <option value="">Select an employee</option>
    {employees.map((employee) => (
        // Add key prop here using a unique identifier
        <option key={employee.employeeId} value={employee.employeeId}>
            {employee.name}
        </option>
    ))}
</select>

                </div>
                <div>
                    <label htmlFor="taskDescription" className="block text-sm font-medium text-gray-700">Task Description</label>
                    <input
                        type="text"
                        id="taskDescription"
                        value={taskDescription}
                        onChange={(e) => setTaskDescription(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                    <input
                        type="date"
                        id="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value.split('T')[0])}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600"
                >
                    Assign Task
                </button>
            </form>

            <TaskTable />
        </>
    );
};

export default Work;
