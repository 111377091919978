import { configureStore } from '@reduxjs/toolkit';
import employeeReducer from '../features/employee/employeeSlice';
import attendanceReducer from '../features/attendance/attendanceSlice';
import workReducer from '../features/work/workSlice'
import payslipReducer from '../features/payslip/payslipSlice'
import authReducer from '../features/auth/authSlice';
import customerReducer from '../features/customerManagement/customerSlice';


 const store = configureStore({
    reducer: {
        employee: employeeReducer,
        attendance: attendanceReducer,
        work: workReducer, 
        payslip: payslipReducer,
        auth: authReducer,
        customers: customerReducer,
      
    },
});

export default store