// src/slices/customerSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCustomers, addCustomer, updateCustomer, removeCustomer } from './customerApi';

const initialState = {
    customers: [],
    status: 'idle',
    error: null,
};

// Thunk to fetch all customers
export const fetchCustomersThunk = createAsyncThunk(
    'customers/fetchCustomers',
    async () => {
        return await fetchCustomers();
    }
);

// Thunk to add a new customer
export const addCustomerThunk = createAsyncThunk(
    'customers/addCustomer',
    async (customerData) => {
        return await addCustomer(customerData);
    }
);

// Thunk to update an existing customer
export const updateCustomerThunk = createAsyncThunk(
    'customers/updateCustomer',
    async (updatedCustomer) => {
        return await updateCustomer(updatedCustomer);
    }
);

// Thunk to remove a customer
export const removeCustomerThunk = createAsyncThunk(
    'customers/removeCustomer',
    async (id) => {
        await removeCustomer(id);
        return id;
    }
);

// Customer slice
const customerSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomersThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCustomersThunk.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.customers = action.payload;
            })
            .addCase(fetchCustomersThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addCustomerThunk.fulfilled, (state, action) => {
                state.customers.push(action.payload);
            })
            .addCase(updateCustomerThunk.fulfilled, (state, action) => {
                const index = state.customers.findIndex(customer => customer.id === action.payload.id);
                if (index !== -1) {
                    state.customers[index] = action.payload;
                }
            })
            .addCase(removeCustomerThunk.fulfilled, (state, action) => {
                state.customers = state.customers.filter(customer => customer.id !== action.payload);
            });
    },
});

export default customerSlice.reducer;
