import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Typography, Card, CardContent, Switch, FormControlLabel } from '@mui/material';
import { useSelector } from 'react-redux';

const Chart = () => {
  const [showAllTimeGraph, setShowAllTimeGraph] = useState(false); // State for graph toggle only

  const employees = useSelector((state) => state.employee.employees);
  const activeEmployees = employees.filter((item) => item.status === 'Active');
  const totalActiveEmployees = activeEmployees.length;

  const workRecords = useSelector((state) => state.work.workRecords);

  // Get the current year and month
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // 0-based month index
  const currentYear = currentDate.getFullYear();

  // Filter the work records for completed tasks within the current month
  const completedThisMonth = workRecords.filter((work) => {
    const workDate = new Date(work.date); // Assuming work.date is in a valid date format
    return (
      work.status === 'Completed' &&
      workDate.getMonth() === currentMonth &&
      workDate.getFullYear() === currentYear
    );
  });

  const activeWorks = workRecords.filter((item) => item.status === 'Pending');
  const currentActiveWork = activeWorks.length;

  // Use either all-time data or current month data based on the graph toggle switch
  const graphDataToDisplay = showAllTimeGraph ? workRecords : completedThisMonth;

  const employeeGraphData = employees.map((employee) => {
    const completedWorkCount = graphDataToDisplay.filter(
      (work) => work.employeeId === employee.employeeId && work.status === 'Completed'
    ).length;

    return { name: employee.name, work: completedWorkCount };
  });

  const handleGraphToggleChange = () => {
    setShowAllTimeGraph((prev) => !prev);
  };

  return (
    <Box
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 4,
        backgroundColor: '#f3f4f6',
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: 900,
        margin: 'auto', // Center the Box horizontally
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: 2,
          textAlign: 'center',
          color: '#1e293b',
          fontWeight: 'bold',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
        }}
      >
        Employee Dashboard
      </Typography>

      {/* Larger Rounded Statistic Cards */}
      <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 2, width: '100%' }}>
        <Card
          sx={{
            backgroundColor: '#0088FE',
            color: '#fff',
            borderRadius: '12px',
            width: 200,
            height: 150, // Adjusted height for better spacing
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.05)', // Scale effect on hover
            },
          }}
        >
          <CardContent sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '2rem' }}>
              {totalActiveEmployees}
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: '1rem' }}>
              Active Employees
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            backgroundColor: '#00C49F',
            color: '#fff',
            borderRadius: '12px',
            width: 200,
            height: 150, // Adjusted height for better spacing
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.05)', // Scale effect on hover
            },
          }}
        >
          <CardContent sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '2rem' }}>
              {currentActiveWork}
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: '1rem' }}>
              Active Work
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Employee Performance Bar Chart */}
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '12px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          p: 2,
          width: '100%', 
          maxWidth: 800, 
          height: 300,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            textAlign: 'center',
            color: '#1e293b',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
        >
          Employee Performance {showAllTimeGraph ? '(All-Time)' : '(This Month)'}
        </Typography>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={employeeGraphData}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="work" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Box>

      {/* Toggle Switch for Graph Only - Now Positioned Under the Graph */}
      <FormControlLabel
        control={
          <Switch
            checked={showAllTimeGraph}
            onChange={handleGraphToggleChange}
            color="primary"
          />
        }
        label={showAllTimeGraph ? 'Graph: All-Time Data' : 'Graph: Current Month Data'}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default Chart;
