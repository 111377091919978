import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from './snmr-logo.png'; 
import './Payslip.css'; 
const Payslip = () => {
    // Fetch employees from Redux state
    const employees = useSelector((state) => state.employee.employees);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
    
    // Manual input fields for employee information
    const [payDate, setPayDate] = useState("");
    const [payType, setPayType] = useState("");
    const [payrollNumber, setPayrollNumber] = useState("");
    const [transactionid, setTransactionId] = useState("");

    // Manual input fields for earnings
    const [standardPay, setStandardPay] = useState("");
    const [overtimePay, setOvertimePay] = useState("");
    const [holidayPay, setHolidayPay] = useState("");
    const [commissionBonus, setCommissionBonus] = useState("");

    // Manual input fields for deductions
    const [pf, setPf] = useState("");
    const [absent, setAbsent] = useState("");
    const deducted_salary = (standardPay/30)*absent ;
   

    const selectedEmployee = employees.find(emp => emp.employeeId === selectedEmployeeId);

    const handleSelectChange = (e) => {
        setSelectedEmployeeId(e.target.value);
    };

    const generatePDF = () => {
        if (!selectedEmployee) return;

        const doc = new jsPDF();
        doc.setFontSize(12);
        const companyName = "SNMR Cloud Services Pvt. Ltd.";
       const phone = "+91 9031077212";
        const email = "info@snmrgroup.com";
        const bank = "KOTHAK MAHINDRA BANK";
        const ifsc = "KKBK0004611";
        const acc_no = "6113088973"

        // Company Info
        doc.text(companyName, 10, 10);
        doc.text(`Email: ${email}`, 10, 20);
        doc.text(`Phone: ${phone}`,10,30);
       


        // Add logo to the top right corner
        doc.addImage(logo, 'PNG', 70, 5, 40, 15); 

        // Employee Information Section
        doc.setFontSize(14);
        doc.text("Employee Information", 10, 40);
        doc.setFontSize(12);
        doc.text(`Full Name: ${selectedEmployee.name}`, 10, 50);
        doc.text(`Employee ID: ${selectedEmployee.employeeId}`, 10, 60);
        doc.text(`Address: ${selectedEmployee.address || '123 Any Street, City'}`, 10, 70);
        doc.text(`Email: ${selectedEmployee.email}`, 10, 80);

        // Pay Details Section (Manual Entries)
        doc.text(`Bank Name :- ${bank}`, 120, 20);
        doc.text(`IFSC Code :- ${ifsc}`, 120, 30);
        doc.text(`Account Number :- ${acc_no}`, 120, 40);
        doc.text(`Pay Date: ${payDate}`, 120, 50);
        doc.text(`Pay Type: ${payType}`, 120, 60);
        doc.text(`Payroll Number: ${payrollNumber}`, 120, 70);
        doc.text(`Transaction Id: ${transactionid}`, 120, 80);

        // Convert earnings and deductions to numbers for PDF generation
        const earnings = [
            ['Basic Pay', '30', Number(standardPay/30).toFixed(2), Number(standardPay).toFixed(2), Number(standardPay).toFixed(2)],
            ['Overtime Pay', '', '', Number(overtimePay).toFixed(2), Number(overtimePay).toFixed(2)],
            ['Holiday Pay', '', '', Number(holidayPay).toFixed(2), Number(holidayPay).toFixed(2)],
            ['Commission and Bonus', '', '', Number(commissionBonus).toFixed(2), Number(commissionBonus).toFixed(2)],
        ];

        // Earnings Table
        doc.autoTable({
            startY: 90,
            head: [['EARNINGS', 'DAYS', 'AMOUNT/DAY', 'CURRENT', 'TOTAL AMOUNT']],
            body: earnings,
        });

        // Convert deductions to numbers for PDF generation
        const deductions = [
            ['Provident Fund', Number(pf).toFixed(2), Number(pf).toFixed(2)],
            ['Leave Deduction', Number(deducted_salary).toFixed(2), Number(deducted_salary).toFixed(2)],
            
           
        ];

        // Deductions Table
        doc.autoTable({
            startY: doc.autoTable.previous.finalY + 10,
            head: [['DEDUCTIONS', 'CURRENT', 'Total Amount']],
            body: deductions,
        });

        // Gross Pay and Net Pay Summary
        const grossPay = (Number(standardPay) + Number(overtimePay) + Number(holidayPay) + Number(commissionBonus)).toFixed(2);
        const totalDeductions = (Number(pf) + Number(deducted_salary)).toFixed(2);
        const netPay = (grossPay - totalDeductions).toFixed(2);

        doc.text(`Total Salary : Rs. ${grossPay} /-`, 10, doc.autoTable.previous.finalY + 20);
        doc.text(`Total Deductions: Rs. ${totalDeductions} /-`, 10, doc.autoTable.previous.finalY + 30);
        doc.text(`Net Pay: Rs .${netPay} /-`, 10, doc.autoTable.previous.finalY + 40);

        doc.save(`payslip_${selectedEmployee.name}.pdf`);
    };

    return (
        <div className="payslip-container">
            <h2>Payslip Generator</h2>

            {/* Employee selection dropdown */}
            <div>
                <label htmlFor="employeeSelect">Select Employee: </label>
                <select id="employeeSelect" value={selectedEmployeeId} onChange={handleSelectChange}>
                    <option value="">-- Select an Employee --</option>
                    {employees.map(employee => (
                        <option key={employee.employeeId} value={employee.employeeId}>
                            {employee.name} (ID: {employee.employeeId})
                        </option>
                    ))}
                </select>
            </div>

            {/* Manual Input Fields */}
            <div>
                <label>Pay Date:</label>
                <input type="date" value={payDate} onChange={(e) => setPayDate(e.target.value)} />
                
                <label>Pay Type:</label>
                <input type="text" value={payType} onChange={(e) => setPayType(e.target.value)} />

                <label>Payroll #:</label>
                <input type="text" value={payrollNumber} onChange={(e) => setPayrollNumber(e.target.value)} />

                <label>Transaction Id:</label>
                <input type="text" value={transactionid} onChange={(e) => setTransactionId(e.target.value)} />
            </div>

            {/* Manual Input Fields for Earnings */}
            <div className="table-section">
                <h3>Earnings</h3>
                <label>Basic Pay:</label>
                <input type="number" value={standardPay} onChange={(e) => setStandardPay(e.target.value)} />

                <label>Overtime Pay:</label>
                <input type="number" value={overtimePay} onChange={(e) => setOvertimePay(e.target.value)} />

                <label>Holiday Pay:</label>
                <input type="number" value={holidayPay} onChange={(e) => setHolidayPay(e.target.value)} />

                <label>Commission and Bonus:</label>
                <input type="number" value={commissionBonus} onChange={(e) => setCommissionBonus(e.target.value)} />
            </div>

            {/* Manual Input Fields for Deductions */}
            <div className="table-section">
                <h3>Deductions</h3>
                <label>Provident Fund:</label>
                <input type="number" value={pf} onChange={(e) => setPf(e.target.value)} />

                <label>Absent Days:</label>
                <input type="number" value={absent} onChange={(e) => setAbsent(e.target.value)} />
            </div>

            {/* Display generate button */}
            {selectedEmployee && (
                <div>
                    <button onClick={generatePDF}>Download Payslip as PDF</button>
                </div>
            )}
        </div>
    );
};

export default Payslip;