import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAttendance, markAttendance, updateAttendance } from './attendanceApi';
import { fetchEmployees } from '../employee/employeeApi';

const initialState = {
    attendance: [],
    employees: [],
    status: 'idle',
    error: null,
};

// Thunk to fetch attendance records and employee data
export const fetchAttendanceAndEmployeesThunk = createAsyncThunk(
    'attendance/fetchAttendanceAndEmployees',
    async () => {
        const attendance = await fetchAttendance();
        const employees = await fetchEmployees();
        return { attendance, employees };
    }
);

// Thunk to mark attendance for an employee
export const markAttendanceThunk = createAsyncThunk(
    'attendance/markAttendance',
    async (attendanceRecord) => {
        const response = await markAttendance(attendanceRecord);
        return response;
    }
);

// Thunk to edit an attendance record
export const editAttendanceThunk = createAsyncThunk(
    'attendance/editAttendance',
    async (updatedRecord) => {
        const response = await updateAttendance(updatedRecord);
        return response;
    }
);

const attendanceSlice = createSlice({
    name: 'attendance',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAttendanceAndEmployeesThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAttendanceAndEmployeesThunk.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.attendance = action.payload.attendance;
                state.employees = action.payload.employees;
            })
            .addCase(fetchAttendanceAndEmployeesThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(markAttendanceThunk.fulfilled, (state, action) => {
                state.attendance.push(action.payload);
            })
            .addCase(editAttendanceThunk.fulfilled, (state, action) => {
                const index = state.attendance.findIndex(record => record.id === action.payload.id);
                if (index !== -1) {
                    state.attendance[index] = action.payload;
                }
            });
    },
});

export default attendanceSlice.reducer;
