
const BASE_URL = 'https://snmr-erp-backend.onrender.com/employees';

export const fetchEmployees = async () => {
    const response = await fetch(`${BASE_URL}/allEmployees`);
    if (!response.ok) {
        throw new Error('Failed to fetch employees');
    }
    const data = await response.json();
    return data;
};

export const addEmployeeApi = async (employee) => {
    const response = await fetch(`${BASE_URL}/createEmployee`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(employee),
    });
    if (!response.ok) {
        throw new Error('Failed to add employee');
    }
    const data = await response.json();
    return data;
};

export const updateEmployeeApi = async (id, updatedData) => {

    const response = await fetch(`${BASE_URL}/resigneEmployee/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    });
    
    if (!response.ok) {
      throw new Error('Failed to update employee');
    }
    
    const data = await response.json();
    return data;
  };
  

export const deleteEmployeeApi = async (employeeId) => {
    const response = await fetch(`${BASE_URL}/employees/${employeeId}`, {
        method: 'DELETE',
    });
    if (!response.ok) {
        throw new Error('Failed to delete employee');
    }
    return employeeId;
};
