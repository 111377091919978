import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEmployees, addEmployeeApi, updateEmployeeApi, deleteEmployeeApi } from './employeeApi';

const initialState = {
    employees: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

export const fetchEmployeesThunk = createAsyncThunk('employee/fetchEmployees', async () => {
    const employees = await fetchEmployees();
    return employees;
});

export const addEmployeeThunk = createAsyncThunk('employee/addEmployee', async (employee, { rejectWithValue }) => {
    try {
        const newEmployee = await addEmployeeApi(employee);
        return newEmployee;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const updateEmployeeThunk = createAsyncThunk('employee/updateEmployee', async ({ id, updatedData }, { rejectWithValue }) => {
    try {
        const updatedEmployee = await updateEmployeeApi(id, updatedData);
        return updatedEmployee;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteEmployeeThunk = createAsyncThunk('employee/deleteEmployee', async (id, { rejectWithValue }) => {
    try {
        await deleteEmployeeApi(id);
        return id;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmployeesThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEmployeesThunk.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.employees = action.payload; // Add fetched employees to the state
            })
            .addCase(fetchEmployeesThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message; // Set error message
            })
            .addCase(addEmployeeThunk.fulfilled, (state, action) => {
                state.employees.push(action.payload); // Add the new employee to the state
            })
            .addCase(addEmployeeThunk.rejected, (state, action) => {
                state.error = action.payload; // Set error message for failed addition
            })
            .addCase(updateEmployeeThunk.fulfilled, (state, action) => {
                const index = state.employees.findIndex(employee => employee.id === action.payload.id);
                if (index !== -1) {
                    state.employees[index] = action.payload; // Update the employee in the state
                }
            })
            .addCase(updateEmployeeThunk.rejected, (state, action) => {
                state.error = action.payload; // Set error message for failed update
            })
            .addCase(deleteEmployeeThunk.fulfilled, (state, action) => {
                state.employees = state.employees.filter(employee => employee.id !== action.payload); // Remove employee from state
            })
            .addCase(deleteEmployeeThunk.rejected, (state, action) => {
                state.error = action.payload; // Set error message for failed deletion
            });
    },
});

export default employeeSlice.reducer;
