const BASE_URL = 'https://snmr-erp-backend.onrender.com';

// API to fetch employee data using fetch
export const fetchEmployeeData = async () => {
  const response = await fetch(`${BASE_URL}/employees`);
  if (!response.ok) {
    throw new Error('Failed to fetch employee data');
  }
  const data = await response.json();
  return data;
};

// API to fetch attendance data using fetch
export const fetchAttendanceData = async () => {
  const response = await fetch(`${BASE_URL}/attendance`);
  if (!response.ok) {
    throw new Error('Failed to fetch attendance data');
  }
  const data = await response.json();
  return data;
};
