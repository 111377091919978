// workSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchWorkByEmployeeAndDate,
    addWorkRecordApi,
    updateWorkRecordApi,
    deleteWorkRecordApi,
    fetchAllWorkRecords
} from './workApi';

const initialState = {
    workRecords: [],
    allWorkRecordsByDate: [],
    loading: false,
    error: null,
};

// Thunks
export const fetchWorkRecords = createAsyncThunk(
    'work/fetchWorkRecords',
    async (_, { rejectWithValue }) => {
        try {
            return await fetchAllWorkRecords(); // Fetch all records
        } catch (err) {
            console.error('Error fetching work records:', err);
            return rejectWithValue(err.message);
        }
    }
);

export const addWorkRecord = createAsyncThunk(
    'work/addWorkRecord',
    async (workRecord, { rejectWithValue }) => {
        try {
            return await addWorkRecordApi(workRecord); // Add new work record
        } catch (err) {
            console.error('Error adding work record:', err);
            return rejectWithValue(err.message);
        }
    }
);

export const updateWorkRecord = createAsyncThunk(
    'work/updateWorkRecord',
    async ({ _id, updatedData }, { rejectWithValue }) => {
       
        try {
            return await updateWorkRecordApi(_id, updatedData); // Update existing work record
        } catch (err) {
            console.error('Error updating work record:', err);
            return rejectWithValue(err.message);
        }
    }
);

export const deleteWorkRecord = createAsyncThunk(
    'work/deleteWorkRecord',
    async (id, { rejectWithValue }) => {
        try {
            return await deleteWorkRecordApi(id); // Delete work record
        } catch (err) {
            console.error('Error deleting work record:', err);
            return rejectWithValue(err.message);
        }
    }
);

export const fetchAllWorkRecordsByDate = createAsyncThunk(
    'work/fetchAllWorkRecordsByDate',
    async (date, { rejectWithValue }) => {
        try {
            return await fetchWorkByEmployeeAndDate(date); // Fetch work records by date
        } catch (err) {
            console.error('Error fetching work records by date:', err);
            return rejectWithValue(err.message);
        }
    }
);

// Slice
const workSlice = createSlice({
    name: 'work',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch all work records
            .addCase(fetchWorkRecords.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWorkRecords.fulfilled, (state, action) => {
                state.loading = false;
                state.workRecords = action.payload; // Update workRecords with all records
            })
            .addCase(fetchWorkRecords.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Fetch all work records by date
            .addCase(fetchAllWorkRecordsByDate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllWorkRecordsByDate.fulfilled, (state, action) => {
                state.loading = false;
                state.allWorkRecordsByDate = action.payload; // Update records by date
            })
            .addCase(fetchAllWorkRecordsByDate.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Add work record
            .addCase(addWorkRecord.fulfilled, (state, action) => {
                state.workRecords.push(action.payload); // Add new record to state
            })
            // Update work record
            .addCase(updateWorkRecord.fulfilled, (state, action) => {
                const index = state.workRecords.findIndex(record => record._id === action.payload._id);
                if (index !== -1) {
                    state.workRecords[index] = action.payload; // Update existing record
                }
            })
            // Delete work record
            .addCase(deleteWorkRecord.fulfilled, (state, action) => {
                // Ensure action.payload is the ID of the deleted record
                state.workRecords = state.workRecords.filter(record => record._id !== action.meta.arg); // Use action.meta.arg to access the ID passed to the thunk
            });

    },
});

export default workSlice.reducer;
