import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Modal from 'react-modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { fetchEmployeesThunk, updateEmployeeThunk } from './employeeSlice';
import { Button } from '@mui/material';

const EmployeeList = () => {
    const employees = useSelector((state) => state.employee.employees || []);
    const status = useSelector((state) => state.employee.status);
    const error = useSelector((state) => state.employee.error);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        dispatch(fetchEmployeesThunk());
    }, [dispatch]);

    // Wrap handleResign in useCallback to prevent unnecessary re-renders
    const handleResign = useCallback(async (id) => {
        if (window.confirm('Are you sure you want to resign this employee?')) {
            const updatedData = { status: "Resigned" };
            await dispatch(updateEmployeeThunk({ id, updatedData }));
            dispatch(fetchEmployeesThunk());
        }
    }, [dispatch]);

    const openImageModal = (imageUrl) => {
        setSelectedImage(imageUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    const columnDefs = useMemo(() => [
        { headerName: 'Emp ID', field: 'employeeId' },
        { headerName: 'Name', field: 'name' },
        { headerName: 'Email', field: 'email' },
        { headerName: 'Phone', field: 'phone' },
        { headerName: 'Role', field: 'role' },
        { headerName: 'Salary', field: 'salary' },
        { headerName: 'Join Date', field: 'joinDate' },
        { headerName: 'Status', field: 'status' },
        {
            headerName: 'Photo',
            field: 'photo',
            cellRenderer: ({ value }) => (
                <img
                    src={value}
                    alt="Employee"
                    style={{ width: '40px', borderRadius: '50%', cursor: 'pointer' }}
                    onClick={() => openImageModal(value)}
                />
            )
        },
        {
            headerName: 'Actions',
            field: 'id',
            cellRenderer: ({ data }) => (
                <Button
                    size="small"
                    onClick={() => handleResign(data._id)}
                    disabled={data.status === "Resigned"}
                >
                    Resign
                </Button>
            )
        }
    ], [handleResign]); // Include handleResign in the dependency array

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="ag-theme-alpine p-4">
            <h2 className="text-[1.5rem] font-bold mb-2 text-center text-white shadow-lg bg-slate-400">
                Employee List
            </h2>

            <AgGridReact
                rowData={employees}
                columnDefs={columnDefs}
                domLayout='autoHeight'
                gridOptions={{
                    getRowHeight: () => 30,
                }}
            />

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Employee Image"
                style={{
                    content: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        border: 'none',
                        borderRadius: '10px',
                        maxWidth: '40vw',
                        padding: '20px',
                        position: 'relative',
                        height: 'auto',
                        marginTop: "10%",
                        marginLeft: "30%"
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                    }
                }}
            >
                <IconButton onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '10px', background: 'transparent', padding: '0', color: 'black' }}>
                    <CloseIcon />
                </IconButton>
                {selectedImage && (
                    <img
                        src={selectedImage}
                        alt="Employee"
                        className="w-full h-auto max-h-[80vh] object-contain"
                    />
                )}
            </Modal>
        </div>
    );
};

export default EmployeeList;
