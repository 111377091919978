import React from 'react';
import { motion } from 'framer-motion';
import Work from '../features/work/Work';

const Report = () => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.7, ease: 'easeOut' }}
      >
        <Work />
      </motion.div>
    </div>
  );
};

export default Report;
