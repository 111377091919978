import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editAttendanceThunk, fetchAttendanceAndEmployeesThunk, markAttendanceThunk } from './attendanceSlice';
import { saveAs } from 'file-saver';
import Papa from 'papaparse'; // For exporting CSV
import EditIcon from '@mui/icons-material/Edit';
import EditModal from "./EditModal";
import { useCallback } from 'react';

const EmployeeAttendance = () => {
    const attendance = useSelector((state) => state.attendance.attendance);
    const employees = useSelector((state) => state.employee.employees);
    const dispatch = useDispatch();
    const [employeeId, setEmployeeId] = useState("");
    const [name, setName] = useState("")
    const [status, setStatus] = useState('');
    const [inTime, setInTime] = useState('');
    const [outTime, setOutTime] = useState('');
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]); // Default to today
    const [selectedMonth, setSelectedMonth] = useState('');
    const [attendanceSummary, setAttendanceSummary] = useState({});

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const formattedYesterday = yesterday.toISOString().split('T')[0];
    const calculateMonthlyAttendance = useCallback((month) => {
        const summary = {};
        employees.forEach((employee) => {
            if (employee.status === 'Active') {
                const employeeAttendance = attendance.filter(
                    (record) =>
                        record.employeeId === employee.employeeId &&
                        record.date &&
                        record.date.startsWith(month)
                );

                const attendanceCount = {
                    present: 0,
                    absent: 0,
                    halfDay: 0,
                    wfh: 0,
                    paidLeave: 0,
                };

                employeeAttendance.forEach((record) => {
                    const status = record.status?.toLowerCase().replace(/\s+/g, ''); // Remove all spaces
                    if (status === 'present') {
                        attendanceCount.present++;
                    } else if (status === 'absent') {
                        attendanceCount.absent++;
                    } else if (status === 'wfh') {
                        attendanceCount.wfh++;
                    } else if (status === 'paidleave' || status === "paid leave") {
                        attendanceCount.paidLeave++;
                    } else if (status === 'halfday' || status === "half day") {
                        attendanceCount.halfDay++;
                    }
                });

                summary[employee.employeeId] = {
                    name: employee.name,
                    ...attendanceCount,
                };
            }
        });

        setAttendanceSummary(summary);
    }, [attendance, employees]);

    useEffect(() => {
        dispatch(fetchAttendanceAndEmployeesThunk());
    }, [dispatch]);

    useEffect(() => {
        if (selectedMonth) {
            calculateMonthlyAttendance(selectedMonth);
        }
    }, [selectedMonth, attendance, calculateMonthlyAttendance]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const attendanceRecord = { employeeId, name, status, inTime, outTime, date };
        dispatch(markAttendanceThunk(attendanceRecord));

        setEmployeeId('');
        setStatus('');
        setInTime('');
        setOutTime('');
        setDate(new Date().toISOString().split('T')[0]); // Set to next day (assuming logic will reset this daily)
    };

    const handleExport = () => {
        // Get yesterday's date
        const date = new Date();
        date.setDate(date.getDate() - 1); // Set date to yesterday
        const previousDate = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD

        // Filter attendance records for yesterday
        const dailyAttendance = attendance.filter(record => record.date === previousDate);

        // Check if there are any records to export
        if (dailyAttendance.length === 0) {
            console.log('No attendance records found for yesterday.');
            return;
        }

        // Map attendance records to include employee names instead of IDs
        const attendanceData = dailyAttendance.map(record => {
            const employee = employees.find(emp => emp.employeeId === record.employeeId);
            return {
                EmployeeName: employee ? employee.name : 'Unknown',
                Status: record.status,
                InTime: record.inTime,
                OutTime: record.outTime,
                Date: record.date,
            };
        });

        // Convert the mapped attendance records to CSV
        const csv = Papa.unparse(attendanceData);

        // Create a blob for the CSV and trigger the download
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `attendance_records_${previousDate}.csv`); // Use the previous date for the filename
    };





    const filterEmployeesByAttendance = () => {
        return employees.filter(employee =>
            !attendance.some(record =>
                record.employeeId === employee.employeeId && record.date === date
            ) && employee.status === 'Active'
        );
    };

    //For modal
    const handleEdit = (record) => {
        setEditingRecord(record);

        setEditModalOpen(true);
    };

    const handleSaveEdit = (updatedRecord) => {
        dispatch(editAttendanceThunk(updatedRecord));
        setEditingRecord(null);
    };

    return (
        <div className="p-4">
            <h2 className="text-[1.5rem] font-bold mb-2 text-center text-white shadow-lg bg-slate-400">
                Employee Attendance
            </h2>

            <form onSubmit={handleSubmit} className="space-y-4 mb-6">
                <div>
                    <label className="block mb-1">Employee</label>
                    <select
                        value={employeeId}
                        onChange={(e) => {
                            const selectedEmployeeId = e.target.value;
                            setEmployeeId(selectedEmployeeId);

                            // Find the selected employee by id and set the name accordingly
                            const selectedEmployee = filterEmployeesByAttendance().find(
                                (employee) => employee.employeeId === selectedEmployeeId
                            );
                            if (selectedEmployee) {
                                setName(selectedEmployee.name);
                            }
                        }}
                        className="border px-4 py-2 w-full"
                    >
                        <option value="">Select Employee</option>
                        {filterEmployeesByAttendance().map((employee) => (
                            // Add key here
                            <option key={employee.employeeId} value={employee.employeeId}>
                                {employee.name} - {employee.role}
                            </option>
                        ))}
                    </select>


                </div>
                <div>
                    <label className="block mb-1">Status</label>
                    <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="border px-4 py-2 w-full"
                    >
                        <option value="">Select Status</option>
                        <option value="Present">Present</option>
                        <option value="Absent">Absent</option>
                        <option value="HalfDay">Half Day</option>
                        <option value="WFH">Work From Home</option>
                        <option value="PaidLeave">Paid Leave</option>
                    </select>
                </div>
                <div>
                    <label className="block mb-1">Date</label>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="border px-4 py-2 w-full"

                    />
                </div>
                <div>
                    <label className="block mb-1">In Time</label>
                    <input
                        type="time"
                        value={inTime}
                        onChange={(e) => setInTime(e.target.value)}
                        className="border px-4 py-2 w-full"
                    />
                </div>
                <div>
                    <label className="block mb-1">Out Time</label>
                    <input
                        type="time"
                        value={outTime}
                        onChange={(e) => setOutTime(e.target.value)}
                        className="border px-4 py-2 w-full"
                    />
                </div>
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                    Mark Attendance
                </button>
            </form>

            <h3 className="text-[1.5rem] font-bold mb-2 text-center text-white shadow-lg bg-slate-400">Yesterday Attendance Records</h3>
            <table className="min-w-full table-auto">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-4 py-2">Employee Name</th>
                        <th className="px-4 py-2">Role</th>
                        <th className="px-4 py-2">Status</th>
                        <th className="px-4 py-2">In Time</th>
                        <th className="px-4 py-2">Out Time</th>
                        <th className="px-4 py-2">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {attendance

                        .filter(record => record.date === formattedYesterday)
                        .map((record, index) => {
                            const employee = employees.find(emp => emp.employeeId === record.employeeId);
                            return (
                                <tr key={index} className="border-b">
                                    <td className="px-4 py-2 text-center">{employee?.name}</td>
                                    <td className="px-4 py-2 text-center">{employee?.role}</td>
                                    <td className="px-4 py-2 text-center">{record.status}</td>
                                    <td className="px-4 py-2 text-center">{record.inTime}</td>
                                    <td className="px-4 py-2 text-center">{record.outTime}</td>
                                    <td className="px-4 py-2 text-center">{record.date}</td>
                                    <td className="px-4 py-2 text-center">
                                        <EditIcon
                                            className="cursor-pointer text-blue-500"
                                            onClick={() => handleEdit(record)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}

                </tbody>
            </table>

            <button
                onClick={handleExport}
                className="bg-green-500 text-white px-4 py-2 rounded mb-6"
            >
                Export Attendance
            </button>

            <h3 className="text-lg font-bold mb-4">Attendance Summary</h3>
            <div className="mb-6">
                <label className="block mb-1">Select Month</label>
                <input
                    type="month"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    className="border px-4 py-2 w-full"
                />
            </div>
            {selectedMonth !== "" && <table className="min-w-full table-auto mb-6">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-4 py-2">Employee Name</th>
                        <th className="px-4 py-2">Present</th>
                        <th className="px-4 py-2">Absent</th>
                        <th className="px-4 py-2">Half Day</th>
                        <th className="px-4 py-2">WFH</th>
                        <th className="px-4 py-2">Paid Leave</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(attendanceSummary).map((id) => {
                        const summary = attendanceSummary[id];
                        return (
                            <tr key={id} className="border-b">
                                <td className="px-4 py-2 text-center">{summary.name}</td>
                                <td className="px-4 py-2 text-center">{summary.present}</td>
                                <td className="px-4 py-2 text-center">{summary.absent}</td>
                                <td className="px-4 py-2 text-center">{summary.halfDay}</td>
                                <td className="px-4 py-2 text-center">{summary.wfh}</td>
                                <td className="px-4 py-2 text-center">{summary.paidLeave}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>}

            <EditModal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                record={editingRecord}
                onSave={handleSaveEdit}
            />
        </div>
    );
};

export default EmployeeAttendance;
